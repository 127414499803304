import { compose } from "recompose";
import { graphql } from "react-apollo";

const EnhanceOlapic = ({ OlapicConfigQuery }) =>
  compose(
    graphql(OlapicConfigQuery, {
      props: ({ data }) => ({
        loading: data.loading,
        olapicEnabled:
          !data.loading && !data.error && !!data?.olapicIsEnabled,
      }),
    })
  );

export default EnhanceOlapic;
